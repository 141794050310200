import Rails from '@rails/ujs'
import 'bootstrap'
import jQuery from 'jquery'
import 'chart.js'
import 'animate.css'
import 'chartjs-plugin-colorschemes'
import '../src/restaurants/style.scss'

window.jQuery = jQuery
window.$ = jQuery

Rails.start()
